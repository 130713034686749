import "./services.css";
import LocalizedText from "../LocalizedText";
import ServiceCards from "./Card";

import Container from "react-bootstrap/Container";

function Service() {
  return (
    <Container>
      <section id="services">
        <div className="justify-content-center">
          <h2 className="custom-card-service">
            <LocalizedText textKey="Serviços" />
          </h2>
          <p className="services-info">
            <LocalizedText textKey="Conheça um pouco mais sobre o que podemos fazer por você." />
          </p>
        </div>

        <ServiceCards />
      </section>
    </Container>
  );
}

export default Service;
