import "./projectInfo.css";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import LocalizedText from "../LocalizedText";

function InfoProject() {
  return (
    <Container fluid className="bg-projectInfo">
      <div id="project-target" className="container-projectInfo">
        <h2>Projetos</h2>
        <div>
          <p className="text-projectInfo">
            <LocalizedText textKey="Conheça o resultado de alguns dos sonhos que já realizamos." />
          </p>
        </div>

        <Link to={"/Projects#project-target"}>
          <Button className="button-project">
            <LocalizedText textKey="Ver projetos" />
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default InfoProject;
