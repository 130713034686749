import axios from 'axios';
const configuredLanguage = localStorage.getItem('language') || 'en';

const Api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    params: {
        'locale': configuredLanguage
    }
});

export default Api;