import "./aboutUs.css";

import Container from "react-bootstrap/Container";
import Api from "../../services/Api";
import { useState, useEffect } from "react";

function AboutUs() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const url = `/biografias`;
    Api.get(url)
      .then((biografia) => {
        setData(biografia.data.data);
      });
  }, []);


  return (
    <Container>
      {data?.map((bio, i) => (
        <div key={i} id="about-us">
          <h2 className="about-us-title">{bio?.attributes.title}</h2>
          <p className="about-us-text">{bio?.attributes.text}</p>
        </div>
      ))}
    </Container>
  );
}

export default AboutUs;
