import React, { useEffect, useState } from "react";
import { US, BR } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    const localLanguage = localStorage.getItem("language");
    return localLanguage ? localLanguage : "en";
  });

  const handleLanguageChange = () => {
    setLanguage((prevLanguage) => {
      const newLanguage = prevLanguage === "pt-BR" ? "en" : "pt-BR";
      i18n.changeLanguage(newLanguage);
      localStorage.setItem("language", newLanguage);
      localStorage.setItem("i18nextLng", newLanguage);
      return newLanguage;
    });
    window.location.reload();
  };

  useEffect(() => {
    const localLanguage = localStorage.getItem("language");
    if (localLanguage) return;
    i18n.changeLanguage("en");
    localStorage.setItem("language", "en");
    localStorage.setItem("i18nextLng", "en");
  }, []);

  return (
    <>
    </>
  );
};

export default LanguageSwitcher;
