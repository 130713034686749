import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import { FiSmartphone } from "react-icons/fi";
import { BsLaptop } from "react-icons/bs";
import { MdDesignServices } from "react-icons/md";
import Api from "../../services/Api";

function ServiceCards({ title, subtitle, cardText }) {

  const [services, setServices] = useState([]);

  useEffect(() => {
    const url = `/services?populate=*`;
    Api.get(url)
      .then((services) => {
        setServices(services.data.data);
      });
  }, []);

  const icons = [
    { icon: <FiSmartphone className="icon" />, title: "Mobile apps" },
    { icon: <BsLaptop className="icon" />, title: "Websites" },
    { icon: <MdDesignServices className="icon" />, title: "Design" },
  ];


  return (
    <div className="text-center">
      <Row>
        {
          services?.map((service, i) => (
            <Col sm={12} md={4} key={i}>
              <div id={ i%2 !== 0 ? "division-between-cards" : '' }>
                  <div className="container-icon">
                    {icons.find((i) => i.title === service?.attributes.Name)?.icon}
                  </div>
                  <h3 className="custom-card-service">{service?.attributes.Name}</h3>
                  <p className="container-services-info-size">
                    {service?.attributes.Description}
                  </p>
                  <div className="justify-content-center">
                    <hr className="bottom-bar-service"></hr>
                  </div>
                  </div>
            </Col>
          ))
        }

      </Row>
    </div>
  );
}

export default ServiceCards;
