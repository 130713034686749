import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AllProjects from "./pages/AllProjects";
import NotFound from "./pages/NotFound";
import Case from "./pages/AllProjects/projectsPages/Case";
import './i18n';
import AlertTemplate from "react-alert-template-basic";
import { Provider as AlertProvider } from "react-alert";

function App() {
  return (
    <AlertProvider template={AlertTemplate}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Projects" element={<AllProjects />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/Projects/:id" element={<Case />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
}

export default App;
