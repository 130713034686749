import "./showcase.css";
import LocalizedText from "../LocalizedText";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import Api from "../../services/Api";

function Showcase() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const url = `/banners?populate=*`;
    Api.get(url)
      .then((banners) => {
        setBanner(banners.data.data[0]);
      });
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container fluid className="masthead">
      <div>
        <div id="container-showcase">
          <h1>{banner?.attributes?.Titulo}</h1>
          <h1 className="color-h1-blue">{banner?.attributes?.Subtitulo}</h1>
          <p id="layout-info">
          {banner?.attributes?.Texto}
          </p>
          {(() => {
            if (windowSize.innerWidth < 768) {
              return (
                <a href="#about-us">
                  <button type="button" className="btn-about-us">
                     <LocalizedText textKey="Sobre Nós" />
                  </button>
                </a>
              );
            } else {
              return null;
            }
          })()}
        </div>
      </div>
    </Container>
  );
}

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}

export default Showcase;
