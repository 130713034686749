import { Container } from "react-bootstrap";
import "./forms.css";
import {  useTranslation  } from 'react-i18next';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { positions, useAlert } from "react-alert";

function QuestionForms() {
  const { t } = useTranslation();
  const alert = useAlert();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_971noxh', 'template_9knus16', form.current, 'user_dBh2cKXG6hYtcY3FFqa1L')
      .then(() => {
        alert.show("Email enviado.", {
          type: "success",
          position: positions.MIDDLE,
          timeout: 2000,
        });
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <Container>
      <div id="info-forms">
        <h2>{t("Entre em contato")}</h2>
        <p>{t("Preencha o formulário abaixo")}</p>
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <fieldset id="bg-fieldset">
          <div className="space-between-inputs">
            <label For="name" className="custom-label">
              {t("Nome")}
            </label>
            <br />
            <input
              type="text"
              name="name"
              id="name"
              className="custom-input"
              placeholder={t("Digite seu nome:")}
            />
          </div>

          <div className="space-between-inputs">
            <label For="email" className="custom-label">
              E-mail
            </label>
            <br />
            <input
              type="text"
              name="email"
              id="email"
              className="custom-input"
              placeholder={t("Digite seu e-mail:")}
            />
          </div>

          <div className="space-between-inputs">
            <label For="services" className="custom-label">
              {t("Serviços")}
            </label>
            <br />
            <select name="category" id="category" className="custom-input">
              <option value="site">Website</option>
              <option value="aplicativos">{t("Aplicativos")}</option>
              <option value="logos">Brand design</option>
            </select>
          </div>

          <div className="space-between-inputs">
            <label For="message" className="custom-label">
              {t("Mensagem")}
            </label>
            <textarea
              name="message"
              id="message"
              className="custom-textarea"
            ></textarea>
          </div>

          <div id="button-space">
            <input type="submit" value={t("Enviar")} id="custom-button" />
          </div>
        </fieldset>
      </form>
    </Container>
  );
}

export default QuestionForms;
