import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import LocalizedText from "../LocalizedText";
import { Link } from "react-router-dom";
import MobileImg from "../../pages/AllProjects/projectsPages/components/MobileImg";
import Api from "../../services/Api";

function ProjectDesktop() {
  const [favoritePost, setFavoritePost] = useState([]);

  useEffect(() => {
    const url = `/posts?populate=*`;
    Api.get(url)
      .then((post) => {
        const favorite = post?.data?.data.find(
          (separa) => separa.attributes.favorite === "Yes"
        );
        setFavoritePost(favorite);
      });
  }, []);

  return (
    <section>
        <div>
        <Link to={`/Projects/${ favoritePost?.attributes?.device === 'mobile' ? 'Mobile' : 'Desktop'}/${favoritePost?.id}`}>
                      <MobileImg imgs={favoritePost?.attributes?.imgs?.data.map(
                          (img) => img.attributes.url
                        )} />
                    </Link>
        </div>
    </section>
  );
}

export default ProjectDesktop;
