import "./navbar.css";

import { useLocation } from "react-router-dom";
import LocalizedText from "../LocalizedText";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LanguageSwitcher from "../LanguageSwitcher";

function Header() {
  const location = useLocation();

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src="/img/logo.svg" alt="Logo" id="logo" />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar-dark"
          id="button-hamburguer"
        />

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <navbar-nav>
            <Nav>
              <nav-item>
                <Nav.Link href="/">
                  {(() => {
                    if (location.pathname === "/") {
                      return (
                        <p className="navbar-style-link" id="ativa">
                          <LocalizedText textKey="Início" />
                        </p>
                      );
                    } else {
                      return <p className="navbar-style-link"><LocalizedText textKey="Início" /></p>;
                    }
                  })()}
                </Nav.Link>
              </nav-item>

              <nav-item>
                <Nav.Link href="/#services">
                  <p className="navbar-style-link">
                   <LocalizedText textKey="Serviços" />
                  </p>
                </Nav.Link>
              </nav-item>

              <nav-item>
                <Nav.Link href="/#about-us">
                  <p className="navbar-style-link">
                    <LocalizedText textKey="Sobre Nós" />
                  </p>
                </Nav.Link>
              </nav-item>

              <nav-item>
                <Nav.Link href="/Projects">
                  {(() => {
                    if (location.pathname.startsWith("/All")) {
                      return (
                        <p className="navbar-style-link" id="ativa">
                          <LocalizedText textKey="Projetos" />
                        </p>
                      );
                    } else {
                      return <p className="navbar-style-link">
                        <LocalizedText textKey="Projetos" />
                      </p>;
                    }
                  })()}
                </Nav.Link>
              </nav-item>

              <nav-item>
                <Nav.Link href="/Contact">
                  {(() => {
                    if (location.pathname === "/Contact") {
                      return (
                        <p className="navbar-style-link" id="ativa">
                          <LocalizedText textKey="Contato" />
                        </p>
                      );
                    } else {
                      return (
                        <p className="navbar-style-link left-sapce">
                          <LocalizedText textKey="Contato" />
                        </p>
                      );
                    }
                  })()}
                </Nav.Link>
              </nav-item>
              <nav-item>
                <LanguageSwitcher />
              </nav-item>
            </Nav>
          </navbar-nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
