const translation = {
    "translation": {
       "Início": "Home",
       "Serviços": "Services",
       "Sobre Nós": "About Us",
       "Projetos": "Projects",
       "Contato": "Talk with us",  
       "Desenvolvimento": "Development",
       "Torne suas ideias em realidade": "Make your ideas come true",
       "Conheça o resultado de alguns dos sonhos que já realizamos": "Know the result of some dreams we have already realized",
       "Ver projetos": "Go to projects",
       "Ver projeto": "Go to project",
       "Como trabalhamos": "How we work",
       "Quer um orçamento?": "Want to tell us about your idea?",
       "Nos conte mais sobre a sua ideia e nós entraremos em contato": "Tell us more about your idea and we will contact you",
       "Fazer orçamento": "Contact us",
       "Ainda tem duvidas?": "Still have questions?",
       "Aqui vão alguma das perguntas mais frequentes": "Here are some of the most frequently asked questions",
       "Entre em contato": "Contact us",
       "Preencha o formulário abaixo": "Fill out the form below",
       "Nome": "Name",
       "Digite o seu nome": "Enter your name",
       "Digite o seu email": "Enter your email",
       "Mensagem": "Message",
       "Enviar": "Send",
       "Veja alguns dos nossos projetos": "See some of our projects",
       "Qual foi o desafio?": "What was the challenge?",
       "Visite o site": "Visit the website",
       "Conheça um pouco mais sobre o que podemos fazer por você.": "Learn a little more about what we can do for you.",
       "Aplicativos": "Mobile Apps",
       "O desafio": "The challenge",
       "A missão": "The mission",
       "O feedback": "The feedback",
    }
}

export default translation