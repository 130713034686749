import { Remarkable } from 'remarkable';

const md = new Remarkable();

function renderMarkdownToHTML(markdown) {
  const renderedHTML = md.render(markdown);
  return {__html: renderedHTML};
}

function MarkdownText({ text }) {
  const textHtml = renderMarkdownToHTML(text);

  return <p dangerouslySetInnerHTML={textHtml} />
}

export default MarkdownText;
