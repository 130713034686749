import LocalizedText from "../LocalizedText";

function Info() {
  return (
    <div className="text-center">
      <h2 className="Custom-working-title"><LocalizedText textKey="Como trabalhamos" /></h2>
      <hr className="Custom-working-cross-bar"></hr>
    </div>
  );
}

export default Info;
