import "./mobileImage.css";

function MobileImg({ imgs }) {
  return (
    <>
    {imgs?.length >= 3 &&
      <div class="imgsContainer">
        <img src={imgs[0]} alt="" className="mobileImg imgLeft"/>
        <img src={imgs[1]} alt="" className="mobileImg imgCenter"/>
        <img src={imgs[2]} alt="" className="mobileImg imgRight"/>
      </div>
    }
    </>
  );
}

export default MobileImg;
