import Header from "../../../../components/Header";
import "../components/DesktopButton";
import Footer from "../../../../components/Footer";
import DesktopImg from "../components/DesktopImg";
import LocalizedText from "../../../../components/LocalizedText";
import MarkdownText from "../../../../components/MarkdownText";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import MobileImg from "../components/MobileImg";

export default function Case() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const [data, setData] = useState();
  const [img, setImg] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/api/posts/` + id + "?populate=*";
    fetch(url)
      .then((res) => res.json())
      .then((post) => {
        const vetorImgs = post.data?.attributes.imgs.data.map(
          (img) => img.attributes.url
        );
        setData(post.data);
        setImg(vetorImgs);
      });
  }, [id]);

  return (
    <div>
      <Header />

      <Container className="container">
        <Row className="justify-content-md-center" >
          <Col className="col-sm" lg={8}>
            { data?.attributes?.device === "mobile" ?
              <MobileImg imgs={img} /> :
              <DesktopImg imgs={img} />
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <h3><LocalizedText textKey="A missão"/></h3>
            <MarkdownText text={data?.attributes?.description || ""} />
            <h3><LocalizedText textKey="O desafio"/></h3>
            <MarkdownText text={data?.attributes?.challenger || ""} />
            <h3><LocalizedText textKey="O feedback"/></h3>
            <MarkdownText text={data?.attributes?.feedback || ""} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
